var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-md-12 col-lg-12" },
        [
          _c(
            "c-table",
            {
              ref: "substanceTable",
              attrs: {
                title: "구성성분 목록",
                tableId: "substanceTable",
                columns: _vm.grid.columns,
                data: _vm.grid.data,
                columnSetting: false,
                selection: "multiple",
                rowKey: "mdmChemId",
                filtering: false,
                usePaging: false,
                hideBottom: true,
                gridHeight: "350px",
                editable: _vm.editable,
              },
              scopedSlots: _vm._u([
                {
                  key: "customArea",
                  fn: function ({ props, col }) {
                    return [
                      [
                        col.name === "msdslink" && props.row.mttrid != ""
                          ? _c(
                              "q-chip",
                              {
                                attrs: {
                                  outline: "",
                                  square: "",
                                  color: "blue",
                                  clickable: true,
                                  "text-color": "white",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return (() =>
                                      _vm.msdslink(props.row)).apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [_vm._v(" MSDS ")]
                            )
                          : _vm._e(),
                      ],
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: { label: "LBLADD", icon: "add" },
                            on: { btnClicked: _vm.addrow },
                          })
                        : _vm._e(),
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: { label: "LBLREMOVE", icon: "remove" },
                            on: { btnClicked: _vm.removeRow },
                          })
                        : _vm._e(),
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: { label: "LBLSAVE", icon: "save" },
                            on: { btnClicked: _vm.saveSubstance },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c("c-table", {
            ref: "innertable",
            staticClass: "topcolor-orange",
            attrs: {
              tableId: "innertable",
              title: "구성성분별 규제사항",
              columns: _vm.grid2.columns,
              data: _vm.grid2.data,
              columnSetting: false,
              filtering: false,
              usePaging: false,
              hideBottom: true,
              editable: false,
              gridHeight: _vm.innertableHeight,
            },
          }),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }