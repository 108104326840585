<template>
  <div class="row">
    <div class="col-md-12 col-lg-12">
      <c-table
        ref="substanceTable"
        title="구성성분 목록"
        tableId="substanceTable"
        :columns="grid.columns"
        :data="grid.data"
        :columnSetting="false"
        selection="multiple"
        rowKey="mdmChemId"
        :filtering="false"
        :usePaging="false"
        :hideBottom="true"
        gridHeight="350px"
        :editable="editable"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <!-- <c-btn label="환경부-화학물질정보 연동 추가" v-if="editable" icon="add" @btnClicked="addApiRow" /> -->
            <c-btn label="LBLADD" v-if="editable" icon="add" @btnClicked="addrow" />
            <c-btn label="LBLREMOVE" v-if="editable" icon="remove" @btnClicked="removeRow" />
            <c-btn label="LBLSAVE" v-if="editable" icon="save" @btnClicked="saveSubstance" />
          </q-btn-group>
        </template>
        <template v-slot:customArea="{ props,col }">
          <template>
            <q-chip
              v-if="col.name==='msdslink' && props.row.mttrid != ''"
              outline square
              color="blue"
              :clickable="true"
              @click.stop="() => msdslink(props.row)"
              text-color="white">
              MSDS
            </q-chip>
          </template>
        </template>
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="innertable"
        tableId="innertable"
        title="구성성분별 규제사항"
        class="topcolor-orange"
        :columns="grid2.columns"
        :data="grid2.data"
        :columnSetting="false"
        :filtering="false"
        :usePaging="false"
        :hideBottom="true"
        :editable="false"
        :gridHeight="innertableHeight"
      />
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  components: {
  },
  name: 'chem-components',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmChemMaterialId: '',
        subtabName: '',
        plantCd: null,
      }),
    },
    chemData: {
      type: Object,
      default: () => ({
        plantCd: null,  // 사업장코드
        mdmChemMaterialId: '',  // 화학자재 일련번호
        materialCd: '',  // 자재코드
        mdmChemReviewId: '',  // 사전검토 일련번호
        deliveryVendorCd: '',  // 공급업체 코드
        // mfgVendorCd: '',  // (사용안함) 제조업체 코드
        propertiesStateCd: null,  // 성상-공통코드
        fugacity: '',  // 비산성(고체)
        boilPoint: '',  // 끊는점(액체,기체)
        meltingPoint: '',  // 녹는점
        moleWeight: '',  // 분자량
        usageCd: null,  // 용도-회사별 공통코드
        usageEtc: '',  // 용도 기타
        mixFlag: '',  // 물질구성(단일 N/혼합 Y)
        casNo: '',  // CAS No.
        // hsNo: '',  // (사용안함) HS번호
        // specificGravity: '',  // (사용안함) 비중
        // serialNo: '',  // (사용안함) 고유번호(유독물번호or고시번호)
        // keNo: '',  // (사용안함) KE 번호
        // storagePlace: '',  // (사용안함) 보관장소
        productCategoryCd: null,  // 제품구분
        keepFacilityFlag: 'N',  // 보관시설 유무
        maxKeepAmount: '',  // 최대보관량
        storageFacilityFlag: 'N',  // 저장시설 유무
        maxStorageAmount: '',  // 최대저장량
        manufacturingFacilitiesFlag: 'N',  // 제조 사용시설 여부
        nanoManufacturingFlag: 'N',  // 나노물질 제조 여부
        nanoIncomeFlag: 'N',  // 나노물질 수입 여부
        nanoNoneApplicableFlag: 'N',  // 나노물질 해당없음 여부
        nanoUnknownFlag: 'N',  // 나노물질 모름 여부
        inputDissolutionTank: '',  // 투입 용해조
        productManufClassCd: null,  // 제품제조구분 코드
        chemIngredientsCd: '',  // 구성성분 작성근거
        useFlag: 'Y',  // 사용여부
        licensingFlag: 'N',  // 인허가대상 물질 여부 Y/N
        dangerFlag: 'N',  // 위험물 여부Y/N
        psmFlag: 'N',  // PSM 대상 여부
        makeFlag: 'N',  // 제품분류(제조)
        impFlag: 'N',  // 제품분류(수입)
        buyFlag: 'N',  // 제품분류(구매)
        usingFlag: 'N',  // 제품분류(사용)
        expFlag: 'N',  // 제품분류(수출)
        salesFlag: 'N',  // 제품분류(판매)
        toxicPoisonFlag: 'N',  // 유해화학물질포함여부(유독물질)
        toxicPermitFlag: 'N',  // 유해화학물질포함여부(허가물질)
        toxicLimitFlag: 'N',  // 유해화학물질포함여부(제한물질)
        toxicProhibitFlag: 'N',  // 유해화학물질포함여부(금지물질)
        remarks: '',  // 비고
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        // specialCtype: '',  // (사용안함) 발암성 C-위험성평가용
        // specialRtype: '',  // (사용안함) 생식독성 R-위험성평가용
        // specialMtype: '',  // (사용안함) 생식세표변이원성 M-위험성평가용
        // rcode: '',  // (사용안함) 위험문구(R-phrase)-위험성평가용
        // hcode: '',  // (사용안함) 유해위험문구(H-code)-위험성평가용
        // cmrFlag: '',  // (사용안함) CMR물질여부-위험성평가용
        // boilingPoint: '',  // (사용안함) 끓는점-위험성평가용
        // hrCodeGrade: '',  // (사용안함) 위험문구/유해위험문구 등급-위험성평가용
        // maleficenceForm: '',  // (사용안함) 유해성 발생형태-위험성평가용
        // maleficenceValue: '',  // (사용안함) 유해성 측정값-위험성평가용
        // maleficenceGrade: '',  // (사용안함) 비산성 등급-위험성평가용
        // maleficenceType: '',  // (사용안함) 비산/휘발성 구분-위험성평가용
        // dayVolume: '',  // (사용안함) 하루취급량
        // dayVolumeUnit: '',  // (사용안함) 하루취급량 단위
        // ghsFlag: '',  // (사용안함) GHS 분류코드
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'kr',
      editable: true,
      grid2: {
        columns: [
        ],
        data: [],
      },
      grid: {
        columns: [
          // {
          //   name: 'chemNmKr',
          //   field: 'chemNmKr',
          //   label: '화학물질명(KOR)',
          //   type: 'text',
          //   align: 'left',
          //   style: 'width:350px',
          //   sortable: false,
          // },
          {
            name: 'chemNmEn',
            field: 'chemNmEn',
            label: '화학물질명(ENG)',
            // type: 'text',
            align: 'left',
            sortable: false,
            style: 'width:350px',
          },
          {
            name: 'casNo',
            field: 'casNo',
            label: 'CAS No.',
            align: 'center',
            style: 'width:120px',
            // type: 'text',
            sortable: false,
          },
          // {
          //   name: 'msdslink',
          //   field: 'msdslink',
          //   label: 'MSDS',
          //   type: 'custom',
          //   align: 'center',
          //   sortable: false,
          //   style: 'width:100px',
          //   helpcomment: '산업안전보건공단에서 제공하는 MSDS정보'
          // },
          {
            name: 'molecularFormula',
            field: 'molecularFormula',
            label: '분자식',
            align: 'center',
            style: 'width:120px',
            // type: 'text',
            sortable: false,
          },
          {
            name: 'limitRepval',
            field: 'limitRepval',
            label: '함유량(%)',
            align: 'center',
            style: 'width:80px',
            type: 'number',
            sortable: false,
          },
          // {
          //   label: '함유량(%)',
          //   align: 'center',
          //   child: [
          //     {
          //       name: 'limitHigh',
          //       field: 'limitHigh',
          //       label: '상한',
          //       align: 'center',
          //       style: 'width:90px',
          //       type: 'number',
          //       sortable: false,
          //     },
          //     {
          //       name: 'limitLow',
          //       field: 'limitLow',
          //       label: '하한',
          //       align: 'center',
          //       style: 'width:90px',
          //       type: 'number',
          //       sortable: false,
          //     },
          //     // {
          //     //   name: 'limitLabel',
          //     //   field: 'limitLabel',
          //     //   label: '표기',
          //     //   align: 'center',
          //     //   style: 'width:120px',
          //     //   type: 'text',
          //     //   sortable: false,
          //     // },
          //     {
          //       name: 'limitRepval',
          //       field: 'limitRepval',
          //       label: '대표값',
          //       align: 'center',
          //       style: 'width:80px',
          //       type: 'number',
          //       sortable: false,
          //     },
          //   ]
          // },
          {
            name: 'businessSecFlag',
            field: 'businessSecFlag',
            label: '영업비밀',
            align: 'center',
            style: 'width:80px',
            sortable: false,
            type: 'check',
            true: 'Y',
            false: 'N'
          },
          // {
          //   label: '화학물질 위험성평가용',
          //   align: 'center',
          //   child: [
          //     {
          //       name: 'boilingPoint',
          //       field: 'boilingPoint',
          //       label: '끓는점',
          //       align: 'center',
          //       // type: 'number',
          //       style: 'width:80px',
          //       sortable: false,
          //     },
          //     {
          //       name: 'cmrFlag',
          //       field: 'cmrFlag',
          //       label: 'CMR 물질 여부',
          //       align: 'center',
          //       // type: 'check',
          //       // true: 'Y',
          //       // false: 'N',
          //       style: 'width:80px',
          //       sortable: false,
          //     },
          //     {
          //       name: 'maleficenceForm',
          //       field: 'maleficenceForm',
          //       label: '유해성<br/>발생형태',
          //       align: 'center',
          //       style: 'width:100px',
          //       type: 'select',
          //       comboItems: [
          //         { code: '분진', codeName: '분진(㎎/㎥)' },
          //         { code: '증기', codeName: '증기(ppm)' },
          //       ],
          //       isSelect: false,
          //       sortable: false,
          //     },
          //     {
          //       name: 'maleficenceValue',
          //       field: 'maleficenceValue',
          //       label: '유해성<br>측정값',
          //       align: 'center',
          //       // type: 'number',
          //       style: 'width:80px',
          //       sortable: false,
          //     },
          //     {
          //       name: 'maleficenceType',
          //       field: 'maleficenceType',
          //       label: '비산/휘발성<br/>구분',
          //       align: 'center',
          //       style: 'width:100px',
          //       type: 'select',
          //       comboItems: _result,
          //       isSelect: false,
          //       sortable: false,
          //     },
          //     {
          //       name: 'maleficenceGrade',
          //       field: 'maleficenceGrade',
          //       label: '비산성 등급',
          //       align: 'center',
          //       style: 'width:100px',
          //       type: 'select',
          //       comboItems: [
          //         { code: '4', codeName: '최대' },
          //         { code: '3', codeName: '대' },
          //         { code: '2', codeName: '중' },
          //         { code: '1', codeName: '소' },
          //       ],
          //       isSelect: false,
          //       sortable: false,
          //     },
          //     {
          //       name: 'hrCodeGrade',
          //       field: 'hrCodeGrade',
          //       label: '위험문구/<br>유해위험문구 등급',
          //       align: 'center',
          //       style: 'width:80px',
          //       type: 'select',
          //       comboItems: [
          //         { code: '4', codeName: '최대' },
          //         { code: '3', codeName: '대' },
          //         { code: '2', codeName: '중' },
          //         { code: '1', codeName: '소' },
          //       ],
          //       isSelect: false,
          //       sortable: false,
          //     },
          //     {
          //       name: 'rcode',
          //       field: 'rcode',
          //       label: '위험문구(R-phrase)',
          //       align: 'left',
          //       // type: 'text',
          //       style: 'width:200px',
          //       sortable: false,
          //     },
          //     {
          //       name: 'hcode',
          //       field: 'hcode',
          //       label: '유해위험문구(H-code)',
          //       align: 'left',
          //       // type: 'text',
          //       style: 'width:200px',
          //       sortable: false,
          //     },
          //   ]
          // },
        ],
        data: [],
      },
      searchParam: {
        useFlag: 'Y',
      },
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
      innertableHeight: '',
      headerUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      nationData: [],
      nations: [],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    this.headerUrl = selectConfig.mdm.mam.chem.header.url;
    this.getHeader();
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.innertableHeight = (window.innerHeight - 500) + 'px';
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.mdm.mam.chem.substance.url;
      this.saveUrl = transactionConfig.mdm.mam.chem.substance.save.url;
      this.deleteUrl = transactionConfig.mdm.mam.chem.substance.delete.url;
      this.reglistUrl = selectConfig.mdm.mam.chem.substance.regulate.url;
      this.getDetail();
    },  
    getHeader() {
      this.$http.url = this.headerUrl;
      this.$http.type = 'GET';
      this.$http.param = {headerType: 'check'};
      this.$http.request((_result) => {
        let _items = {
          name: 'chemNmEn',
          field: 'chemNmEn',
          label: '화학물질명(ENG)',
          align: 'left',
          style: 'width:220px',
          sortable: false,
        }
        this.grid2.columns.push(_items);
        this.$_.forEach(_result.data, _item => {
          this.grid2.columns.push(_item);
        })
        this.getLaw();
      },);
    },
    getLaw() {
      this.$http.url = this.reglistUrl;
      this.$http.type = 'GET';
      this.$http.param = {mdmChemMaterialId: this.popupParam.mdmChemMaterialId};
      this.$http.request((_result) => {
        this.grid2.data = _result.data;

        this.$_.forEach(_result.data, _item => {
          this.nationData.push({
            chemNmKr: _item.chemNmKr,
            chemNmEn: _item.chemNmKr,
            casNo: _item.casNo,
            nations: this.$_.filter(this.nations, {casNo: _item.casNo}),
          })
        })
      },);
    },
    getDetail() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {mdmChemMaterialId: this.popupParam.mdmChemMaterialId};
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    addrow() {
      this.popupOptions.target = () => import(`${'@/pages/mdm/mam/chem/chemBasePop.vue'}`);
      this.popupOptions.title = '화학물질';
      this.popupOptions.width = '60%';
      this.popupOptions.param = {
        type: 'multiple',
        isDetail: true,
      }
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeChemBasePopup;
    },
    closeChemBasePopup(_data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if(_data && _data.length > 0) {
        this.$_.forEach(_data, item => {
          if (this.$_.findIndex(this.grid.data, { mdmChemId: item.mdmChemId }) === -1) {
            this.grid.data.splice(0, 0, {
              mdmChemMaterialId: this.popupParam.mdmChemMaterialId,
              mdmChemId: item.mdmChemId,
              casNo: item.casNo,
              chemNmKr: item.chemNmKr,
              chemNmEn: item.chemNmEn,
              limitHigh: 0,
              limitLow: 0,
              limitRepval: 0,
              businessSecFlag: 'N',
              molecularFormula: item.molecularFormula,
              // boilingPoint: item.boilingPoint,
              // cmrFlag: item.cmrFlag,
              // maleficenceForm: item.maleficenceForm,
              // maleficenceValue: item.maleficenceValue,
              // maleficenceType: item.maleficenceType,
              // maleficenceGrade: item.maleficenceGrade,
              // hrCodeGrade: item.hrCodeGrade,
              // rcode: item.rcode,  // 위험문구(R-code)
              // hcode: item.hcode,  // 유해위험문구(H-code)
              // mttrid: item.mttrid,
              editFlag: 'C',
              regUserId: this.$store.getters.user.userId,
            })
          }
        })
      }
    },
    // msdslink(row) {
    //   window.open('https://msds.kosha.or.kr/MSDSInfo/kcic/msdsdetail.do?chem_id='+row.mttrid+'&viewType=msds','msdspop','width=1280, height=768, menubar=no, status=no, toolbar=no');
    // },
    // addApiRow() {
    //   this.popupOptions.target = () => import(`${'@/pages/common/openapi/materialApiPop.vue'}`);
    //   this.popupOptions.title = '환경부-화학물질정보 연동 검색';
    //   this.popupOptions.width = '50%';
    //   this.popupOptions.param = {
    //     type: 'multiple'
    //   }
    //   this.popupOptions.visible = true;
    //   this.popupOptions.closeCallback = this.closePopup;
    // },
    // closePopup(_data) {
    //   this.popupOptions.target = null;
    //   this.popupOptions.visible = false;
    //   if(_data.length !== undefined) {
    //     this.$_.forEach(_data, item => {
    //       if (this.$_.findIndex(this.grid.data, { casNo: item.casno }) === -1) {
    //         this.grid.data.splice(0, 0, {
    //           editFlag: 'C',
    //           mdmChemMaterialId: this.param.mdmChemMaterialId,
    //           mdmMaterialSubstancesId: uid(),
    //           casNo: item.casno ? item.casno : '',
    //           chemNmKr: item.mttrnmkor ? item.mttrnmkor : '',
    //           chemNmEn: item.mttrnmeng ? item.mttrnmeng : '',
    //           limitLabel: '',
    //           limitHigh: '',
    //           limitLow: '',
    //           limitRepval: '',
    //           businessSecFlag: 'N',
    //           cmrFlag: 'N',
    //           boilingPoint: null,
    //           hrCodeGrade: null,
    //           maleficenceForm: null,
    //           maleficenceType: null,
    //           maleficenceValue: '',
    //           molecularFormula: item.molecform ? item.molecform : '',
    //           mttrid: item.chemId ? item.chemId : '',  // 환경부데이터 물질ID(CHEM ID)
    //           regUserId: this.$store.getters.user.userId,
    //         })
    //       }
    //     })
    //   }
    // },
    saveSubstance() {
      let saveData = this.grid.data.filter( x => {
        return x.editFlag == 'C' || x.editFlag == 'U'
      });
      this.$_.forEach(saveData, item => {
        item.regUserId = this.$store.getters.user.userId;
        item.chgUserId = this.$store.getters.user.userId;
      });
      if(saveData.length > 0) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.saveUrl;
            this.$http.type = 'POST';
            this.$http.param = saveData;
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.getDetail();
              this.getLaw();
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } else {
        window.getApp.$emit('ALERT', {
          title: '안내 ', /* 안내 */
          message: 'MSGNOCHANGEDATA', // 변경된 데이터가 없습니다., /* 변경된 데이터가 없습니다. */
          type: 'info', // success / info / warning / error
        });
      }
    },
    removeRow() {
      let selectData = this.$refs['substanceTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getDetail();
              this.getLaw();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs['substanceTable'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>

<style lang="sass">
.substanceTableCss .lanauageFlag
  margin: 0 -1.2em 0 -0.1em !important
.substanceTableCss .q-tab__label
  font-size: 0.80rem
  font-weight: 600
.substanceTableCss .q-tab
  height: 36px
.lawchemChip
  margin-right: 5px !important
  cursor: pointer
.lawchemChiptd
  line-height: 28px !important
.chemavatar
  width: 22px !important
  height: 22px !important
  cursor: pointer
</style>